.contactUs {
    animation: fadeIn 1s;
    padding: 50px;
    text-align: center;
}
  
  .contactUs h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .contactUs p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: whitesmoke;
  }
  
  .contactForm {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .contactForm a {
    display: inline-block;
    padding: 10px 20px;
    background: linear-gradient(158deg, rgb(58, 149, 246) 20%, rgb(122, 20, 255) 100%);
    color: white;
    border-radius: 5px;
    text-decoration: none;
    transition: 0.3s ease; /* Add transition effect */
  }
  
  .contactForm a:hover {
   transform: scale(0.9);
  }
  
  
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
