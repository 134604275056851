.PartnerShip {
    animation: fadeIn 1s;
    padding: 50px;
    text-align: center;
}

.Image {
display: flex;
padding-top: 40px;
align-items: center;
justify-content: center; 
}

.Image img {
  max-width: 100%;
  height: auto;
  display: block;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgb(255, 255, 255,0.4);
}