/* AboutUs.css */
.page-container {
    padding: 40px;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
}

.section-heading {
    font-size: 36px;
    margin-bottom: 20px;
    color: #007bff;
}

.paragraph {
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 30px;
    color: whitesmoke;
}
