.card {
  position: relative; /* Change position to relative */
  margin: 20px auto; /* Center the card horizontally */
  padding: 10px;
  border-radius: 15px;
  max-width: 500px; /* Use max-width instead of fixed width */
  width: 100rem; /* Use percentage width for responsiveness */
  box-shadow: 0px 0px 20px rgb(139, 139, 139);
  background-color: white;
}

.card img {
  max-width: 100%;
  height: auto;
  margin: 0;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
}

@media (max-width: 768px) {
  .card {
    width: 20rem; /* Use percentage width for responsiveness */
    padding: 5px;
    border-radius: 10px;
    margin: 10px auto; /* Adjust margin for smaller screens */
  }

  .card img {
    border-radius: 8px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  }
}
