.counting-container {
    background-color: white;
    border-radius: 10px;
    padding: 40px 90px 40px 90px;
    margin: 10px;
    box-shadow: 0px 4px 20px rgb(255, 255, 255, 0.5);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 30%;
    font-size: medium;
    font-weight: 500;
}

.counting-number {
    display: inline-block;
   
    
}

.container-numbers {
    color: black
}
.font-multiColor{
  background: linear-gradient(158deg, rgb(58, 149, 246) 20%, rgb(122, 20, 255) 100%);
    background-clip: text;
    font-size: large;
    font-weight: bolder;
    -webkit-text-fill-color: transparent;
}
.services-card {
  background-color: white;
  width: 300px;
  height: 200px;
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  animation: slide-up 2s ease forwards;
  transition: transform 1s ease, height 1s ease;
  position: relative;
  overflow: hidden;
}
  
  @keyframes slide-up {
    0% {
      transform: scale(0.5);
      opacity: 0;
      box-shadow: 0px 4px 20px rgba(0, 183, 255, 0.5);
    }
    50% {
      box-shadow: 0px 8px 30px rgba(0, 119, 255, 0.4);
    }
    75% {
      box-shadow: 0px 12px 40px rgba(0, 4, 255, 0.3);
    }
    85%{
        box-shadow: 0px 12px 40px rgba(98, 0, 255, 0.2);

    }
    100% {
      transform: scale(1);
      opacity: 1;
      box-shadow: 0px 4px 20px rgba(0, 195, 255, 0.1);
    }
  }
  
  .services-card:hover {
    transform: scale(1.2) translateY(50px);
    height: 350px; /* Adjust this value depending on your design */
  }
 .icon{
  color: '#3f3f3f';
 }
 .description {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 1s ease, max-height 1s ease;
  text-align: center;
  margin-top: 10px;
}

.services-card:hover .description {
  opacity: 1;
  max-height: 200px; /* Adjust this value depending on your content */
}
  .opacity{
    transition: opacity 0.5s ease;
    opacity: 1;
  }
  .services-card:hover .opacity{
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }

.divider {
    height: 50px;
    width: 1px;
    background: linear-gradient(158deg, rgb(58, 149, 246) 20%, rgb(122, 20, 255) 100%);
    -webkit-text-fill-color: transparent;
    margin-right: 20px;
    margin-left: 20px;
}

.achievements {
    padding-top: 50px;

}

h1 {
    padding-top: 20px;
    color: whitesmoke;
}
.description-counter{
    color: dimgrey;
    font-size: small;
    white-space: pre-line;
}
.typing-animation {
    color: whitesmoke;
    font-size: 36px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {

    .counting-container {

        background-color: white;
        border-radius: 10px;
        padding: 40px 90px 40px 90px;
        margin: 10px;
        box-shadow: 0px 4px 20px rgb(255, 255, 255, 0.5);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 90%;
        font-size: medium;
        font-weight: 500;
    }
    
}